import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Tasks from './Tasks';
function CPTasks({ data,basic }) {
  const heading = {
    color: '#0c8b37',
    fontSize: '22px',
    paddingBottom: '12px',
    paddingTop: '50px',
  };

  const boxstyle = {
    background: '#f9f7f7',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column' }}
        >
          <Typography gutterBottom variant="h4" style={heading}>
            COMPLETED TASKS
          </Typography>
          <Box style={boxstyle} sx={{ width: '100%', height: '100%' }}>
            <Tasks tasks={data.filter(task=>{return task.status == 'closed'})} basic={basic}/>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column' }}
        >
          <Typography gutterBottom variant="h4" style={heading}>
            TASKS IN PROGRESS
          </Typography>
          <Box style={boxstyle} sx={{ width: '100%', height: '100%' }}>
          <Tasks tasks={data.filter(task=>{return task.status == 'in progress'})} basic={basic}/>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default CPTasks;
