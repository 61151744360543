import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import useSettings from '../hooks/useSettings';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { getDashboardData } from 'src/adapters/data/dashboard';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppWelcome,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import account from '../_mock/account';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const [totalProgress, setTotalProgress] = useState(0);
  const [projectTypeProgress, setprojectTypeProgress] = useState([]);
  const [subcityProgress, setsubcityProgress] = useState([]);
  const [projectTypeGraph, setprojectTypeGraph] = useState([]);

  const getData = async () => {
    let dashboard = await getDashboardData();
    setTotalProgress(dashboard.data.overall_progress[0].total_progress);
    setsubcityProgress(dashboard.data.getProjectSummaryBySubcity);
    setprojectTypeProgress(dashboard.data.project_type_summary);
  };

  const formatGraphData = (projectTypeGraph) => {
    let data = [];
    projectTypeGraph.forEach((item) => {
      data.push({
        label: item.project_type_name,
        value: item.total_progress,
      });
    });

    return data;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <AppWelcome
              displayName={account.displayName}
              totalProgress={totalProgress}
              subcityProgress={subcityProgress}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppConversionRates
              title="Project Status"
              subheader="this year"
              chartData={formatGraphData(projectTypeProgress)}
            />
          </Grid>

          {projectTypeProgress.map((projectType) => {
            return (
              <Grid  item xs={12} sm={6} md={3} key={projectType.project_type_id}>
                <AppWidgetSummary
                  title={projectType.project_type_name}
                  total={projectType.total_progress}
                 
                  icon={'ant-design:android-filled'}
                  link={'/dashboard/projectcategory/' + projectType.project_type_id}
                />
              </Grid>
            );
          })}

        </Grid>
      </Container>
    </Page>
  );
}
