import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Stack, Grid, Box, Card, CardContent } from '@mui/material';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
import { useState, useEffect } from 'react';
import ProgressTwo from './ProgressTwo';
import VerticalProgress from './VerticalProgress';
function OverallProgress({data, milestones,basic,tasks}) {

  const heading = {
    color: '#0c8b37',
    fontSize: '22px',
    paddingBottom: '12px',
    paddingTop: '50px',
  };

  const headingTwo = {
    color: '#0c8b37',
    fontSize: '22px',
    fontWeight: '200',
    textAlign: 'center',
  };
  const numberTwo = {
    color: '#0c8b37',
    fontSize: '61px',
  };

  const boxstyle = {
    padding: '20px',
    background: '#f9f7f7',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const headingPercent = {
    color: '#0c8b37',
    fontSize: '25px',
    lineHeight: '30px',
  };
  return (
    <div>
      <Typography gutterBottom variant="h4" style={heading}>
        PROJECT PROGRESS
      </Typography>

      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}
        >
          <Box style={boxstyle} sx={{ width: '100%', height: '100%' }}>
            <Stack direction="row" spacing={4} alignItems="center" justifyContent="center">
              <ProgressTwo percentage={
                (tasks.filter(task => task.status === 'closed').length / tasks.length * 100).toFixed(2)
              }/>

              <Box>
                <Typography gutterBottom variant="h4" style={headingPercent}>
                {
                (tasks.filter(task => task.status === 'closed').length / tasks.length * 100).toFixed(2)
              }
                </Typography>
                <Typography gutterBottom variant="body">
                  in 30 days
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}
        >
          <Box style={boxstyle} sx={{ width: '100%', height: '100%' }}>
            <Stack direction="row" spacing={4} alignItems="center" justifyContent="space-between">
              <VerticalProgress progress={45} progressText="Planned" />
              <VerticalProgress progress={
                (tasks.filter(task => task.status === 'closed').length / tasks.length * 100).toFixed(2)
              } progressText="Achived" />
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}
        >
          <Box style={boxstyle} sx={{ width: '100%', height: '100%' }}>
            <Typography gutterBottom variant="h4" style={headingTwo}>
              Total Remaining <br />
              Days
            </Typography>
            <Typography gutterBottom variant="h1" style={numberTwo}>
              {basic.remaining_days_until_end_date}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default OverallProgress;
