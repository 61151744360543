import * as Yup from 'yup';
import { useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
// material
import { styled } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Delete, RemoveCircleOutline } from '@material-ui/icons';
import { createProject, getProjectOptions } from 'src/adapters/data/project';
import MapSelector from 'src/components/MapSelector';
import { AppStateContext } from 'src/App';
import { useContext } from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';

import {
  Card,
  Chip,
  Grid,
  Stack,
  Radio,
  Switch,
  Select,
  TextareaAutosize,
  TextField,
  InputLabel,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  Container,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';

import Page from '../components/Page';
import Loading from './Loading';

// ----------------------------------------------------------------------

const CATEGORY_OPTION = [
  { group: 'Addis Ababa', classify: ['Sub City', 'Sub City', 'Sub City', 'Sub City'] },
  // { group: 'Debreziet', classify: ['Sub City', 'Sub City', 'Sub City', 'Sub City'] },
  // { group: 'Natheret', classify: ['Sub City', 'Sub City', 'Sub City', 'Sub City'] },
];

export const ProjectCreateStateContext = React.createContext();

const TAGS_OPTION = [
  'team name one',
  'team name two',
  'team name three',
  'team name four',
  'team name five',
  'team name six',
  'team name seven',
  'team name eight',
  'team name nine',
  'team name ten',
  'team name eleven',
  'team name tweleve',
  'team name therteen',
];

ProjectCreate.propTypes = {
  isEdit: PropTypes.bool,
  currentProduct: PropTypes.object,
};

export default function ProjectCreate({ isEdit, currentProduct }) {
  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    images: Yup.array().min(1, 'Images is required'),
  });

  const navigate = useNavigate();

  const appState = useContext(AppStateContext);

  const [projectData, setProjectData] = useState({
    name: '',
    project_type_id: 1,
    team_leader_id: 1,
    subcity_id: '',
    location_lat: '',
    location_long: '',
    description: '',
    start_date: '2022-06-16T00:00:00.000000Z',
    end_date: '2022-08-17T00:00:00.000000Z',
    milestones: [
      {
        name: '',
        description: '',
        tasks: [
          {
            title: '',
            description: '',
          },
        ],
      },
    ],
    team_members: [],
    stake_holders: [],
  });

  const submitProjectData = (evt) => {
    evt.preventDefault();

    setSubmitting(true);

    console.log('projectData', projectData);

    var response = createProject(projectData)
      .then(()=>{
        setSubmitting(false);
        appState.triggerSnackBar(`Successfully created project ${projectData.name}`);
       //redirect to project list
       navigate("/dashboard", { replace: true });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          appState.triggerSnackBar(Object.values(error.response.data.errors)[0][0], 'error');
        }
        setSubmitting(false);
      });
  };

  const [availableTeamMembers, setavailableTeamMember] = useState([]);
  const [subcities, setsubcities] = useState([]);
  const [availableStakeHolders, setavailableStakeHolders] = useState([]);
  const [availableTeamLeaders, setavailableTeamLeaders] = useState([]);

  const [loading, SetLoading] = useState(true);
  
  const [submitting, setSubmitting] = useState(false);

  const [projectTypes, setProjectTypes] = useState([]);

  const [showMap, setshowMap] = useState(false);

  const getProjects = async () => {
    const response = await getProjectOptions();
    SetLoading(false);

    setavailableTeamMember(response.data.data.users);
    setavailableTeamLeaders(response.data.data.users);
    setavailableStakeHolders(response.data.data.users);
    setsubcities(response.data.data.subcities);
    setProjectTypes(response.data.data.project_types);
  };

  useEffect(() => {
    getProjects();
  }, []);



  const updateMapData = (location_lat,location_long)=>{
    setProjectData({...projectData,location_lat,location_long});
  };

  const addMileStone = () => {
    const newMileStones = [...projectData.milestones];
    newMileStones.push({
      name: '',
      description: '',
      tasks: [
        {
          title: '',
          description: '',
        },
      ],
    });
    setProjectData({ ...projectData, milestones: newMileStones });

    appState.triggerSnackBar('Mile Stone Added');
  };

  const removeMileStone = (index) => {
    if (projectData.milestones.length > 1) {
      const newMilestones = [...projectData.milestones];
      newMilestones.splice(index, 1);
      setProjectData({
        ...projectData,
        milestones: newMilestones,
      });
      appState.triggerSnackBar('Mile Stone Removed', 'error');
    }
  };

  const addTask = (index) => {
    const newMilestones = [...projectData.milestones];
    newMilestones[index].tasks = [
      ...newMilestones[index].tasks,
      {
        title: '',
        description: '',
      },
    ];
    setProjectData({
      ...projectData,
      milestones: newMilestones,
    });
    appState.triggerSnackBar('Task Added');
  };

  const removeTask = (index, milestoneIndex) => {
    const newMilestones = [...projectData.milestones];
    newMilestones[milestoneIndex].tasks.splice(index, 1);
    setProjectData({
      ...projectData,
      milestones: newMilestones,
    });
    appState.triggerSnackBar('Task Removed', 'error');
  };

  const ProjectTypes = [
    { title: 1994, id: 1 },
    { title: 1995, id: 2 },
    { title: 1996, id: 3 },
    { title: 1997, id: 4 },
  ];

  const [value, setValue] = React.useState(null);

  return (
    <Page title="Project: Create a new project" onClick={()=>{setshowMap(true)}}>
      {!loading ? (
        <Container maxWidth={'lg'} >
          <Typography variant="h4" gutterBottom>
            Create new Project :  {projectData.name}
          </Typography>

          {/* onMouseMove */}
          

          <form onSubmit={submitProjectData}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    {projectData.location_lat.length == 0 ? <><Alert severity="info">Waiting For Loocation Data</Alert></> : <TextField
                      fullWidth
                      label="Project Name"
                      onChange={(e) => {
                        setProjectData({ ...projectData, name: e.target.value });
                        setshowMap(true);
                      }}
                      disabled={submitting}
                    />}

                    <TextField
                      label="Enter Description here."
                      multiline
                      rows={22}
                      onChange={(e) => {
                        setProjectData({
                          ...projectData,
                          description: e.target.value,
                          
                        });
                        setshowMap(true);
                      }}
                    />
                  </Stack>
                </Card>
                <br />
              </Grid>

              <Grid item xs={12} md={4}>
                <LoadingButton type="submit" loading={submitting} fullWidth variant="contained" size="large">
                  {!isEdit ? 'Create Project' : 'Save Changes'}
                </LoadingButton>
                <br />
                <br />
                <Stack spacing={3}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Start Date"
                          onChange={(val) => {
                           
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="End Date"
                          onChange={(val) => {}}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>

                      <Autocomplete
                        options={subcities}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, val) => {
                          setProjectData({
                            ...projectData,
                            subcity_id: val.id,
                          });
                          setshowMap(true);
                        }}
                        renderInput={(params) => <TextField label="Subcity" {...params} />}
                      />
                      
                       
                        {projectData.location_lat.length == 0 && projectData.location_long.length == 0 ? <Alert severity="error">Waiting for Location</Alert>
 : <></>}
                        {showMap && <MapSelector data={projectData} setter={setProjectData} />}

                      <Autocomplete
                        onChange={(event, val) => {
                          setProjectData({
                            ...projectData,
                            team_leader_id: val.id,
                          });
                        }}
                        options={availableTeamLeaders}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField label="Team Leader" {...params} />}
                      />

                      {/* <Autocomplete
                        multiple
                        onChange={(event, val) => {
                          val.map((user) => {
                            setProjectData({
                              ...projectData,
                              team_members: [...projectData.team_members, { user_id: user.id }],
                            });
                          });
                        }}
                        options={availableTeamMembers}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField label="Team members" {...params} />}
                      />  */}

                      <Autocomplete
                        multiple
                        onChange={(event, val) => {
                          val.map((user) => {
                            setProjectData({
                              ...projectData,
                              stake_holders: [...projectData.stake_holders, { user_id: user.id }],
                            });
                          });
                        }}
                        options={availableStakeHolders}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField label="Stake Holders" {...params} />}
                      />
                    </Stack>
                  </Card>
                </Stack>
              </Grid>

              <Grid xs={12} md={12}>
                <br />
                <br />
                <Card sx={{ p: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Milestone
                  </Typography>

                  {projectData.milestones.map((milestone, index) => (
                    <>
                      <Stack spacing={2} marginTop={3} key={index} direction="row">
                        <TextField
                          label="Milestone Name"
                          onChange={(e) => {
                            setProjectData({
                              ...projectData,
                              milestones: [
                                ...projectData.milestones.slice(0, index),
                                {
                                  ...milestone,
                                  name: e.target.value,
                                },
                                ...projectData.milestones.slice(index + 1),
                              ],
                            });
                          }}
                        />
                        <TextField
                          label="Milestone Description"
                          onChange={(e) => {
                            setProjectData({
                              ...projectData,
                              milestones: [
                                ...projectData.milestones.slice(0, index),
                                {
                                  ...milestone,
                                  description: e.target.value,
                                },
                                ...projectData.milestones.slice(index + 1),
                              ],
                            });
                          }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Start Date"
                            value={projectData.milestones[index].end_date}
                            onChange={(newValue) => {
                              setProjectData({
                                ...projectData,
                                milestones: [
                                  ...projectData.milestones.slice(0, index),
                                  {
                                    ...milestone,
                                    start_date: newValue,
                                  },
                                  ...projectData.milestones.slice(index + 1),
                                ],
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="End Date"
                            value={projectData.milestones[index].end_date}
                            onChange={(newValue) => {
                              setProjectData({
                                ...projectData,
                                milestones: [
                                  ...projectData.milestones.slice(0, index),
                                  {
                                    ...milestone,
                                    end_date: newValue,
                                  },
                                  ...projectData.milestones.slice(index + 1),
                                ],
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                        {projectData.milestones.length > 1 && (
                          <Button onClick={() => removeMileStone(index)}>
                            {' '}
                            <RemoveCircleOutlineIcon color="error" />
                          </Button>
                        )}
                        {index == projectData.milestones.length - 1 && (
                          <Button onClick={() => addMileStone()}>
                            <AddIcon />
                          </Button>
                        )}
                        {/* Buton with icon */}
                      </Stack>

                      {projectData.milestones[index].tasks.map((task, taskIndex) => {
                        return (
                          <Stack spacing={2} margin={5} direction="row">
                            <TextField
                              fullWidth
                              label="Task Name"
                              onChange={(e) => {
                                setProjectData({
                                  ...projectData,
                                  milestones: [
                                    ...projectData.milestones.slice(0, index),
                                    {
                                      ...milestone,
                                      tasks: [
                                        ...milestone.tasks.slice(0, taskIndex),
                                        {
                                          ...task,
                                          title: e.target.value,
                                        },
                                        ...milestone.tasks.slice(taskIndex + 1),
                                      ],
                                    },
                                    ...projectData.milestones.slice(index + 1),
                                  ],
                                });
                              }}
                            />
                            <TextField
                              fullWidth
                              label="Task Description"
                              onChange={(e) => {
                                setProjectData({
                                  ...projectData,
                                  milestones: [
                                    ...projectData.milestones.slice(0, index),
                                    {
                                      ...milestone,
                                      tasks: [
                                        ...milestone.tasks.slice(0, taskIndex),
                                        {
                                          ...task,
                                          description: e.target.value,
                                        },
                                        ...milestone.tasks.slice(taskIndex + 1),
                                      ],
                                    },
                                    ...projectData.milestones.slice(index + 1),
                                  ],
                                });
                              }}
                            />
                            {projectData.milestones[index].tasks.length > 1 && (
                              <Button
                                onClick={() => {
                                  removeTask(taskIndex, index);
                                }}
                              >
                                <RemoveCircleOutline color="error" />
                              </Button>
                            )}
                            <Button
                              onClick={() => {
                                addTask(index);
                              }}
                            >
                              <AddIcon />
                            </Button>
                          </Stack>
                        );
                      })}
                    </>
                  ))}
                </Card>
              </Grid>
            </Grid>
          </form>
        </Container>
      ) : (
        <Loading />
      )}
    </Page>
  );
}
