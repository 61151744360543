import React from 'react';
import { Typography } from '@mui/material';
import { convertColorText } from 'src/utils/colors';

export default function ProjectDoneDs({ totalProgress }) {
  const styles = {
    wrapper: {
      padding: '30px',
    },
  };

  return (
    <div style={styles.wrapper}>
      <Typography variant="h1" color={convertColorText(totalProgress)}>
        {totalProgress}%{' '}
      </Typography>
      Total Projects Done
    </div>
  );
}
