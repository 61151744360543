import React from 'react'
import { Box,Grid,Typography } from '@mui/material'

function FooterLogo() {
  return (
    <div>
        <Grid container spacing={2}>
    
    <Grid item xs={12} md={12} lg={12} sx={{ display:"flex", justifyContent:"flex-end", alignItems:"end", flexDirection:"column"}}>
    <br/><br/>
    <Box component="img" src="/static/logo.png" sx={{ width: 62, height: 58}} />
        </Grid>
        </Grid>
    </div>
  )
}

export default FooterLogo