import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Card, CardContent } from '@mui/material';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
import { useState,useEffect } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: "#0c8b37",
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));



export default function Heading({data}) {


  const heading = {
    color: 'white',
    fontSize: '23px',
    textAlign: 'left',

  };

  const wrapper = {
    display: 'flex',
    padding:'20px',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '234px',
  };

  return (
    <RootStyle>
      <CardContent
        style={wrapper}
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: 'grey.800',
        }}
      >
        <Typography gutterBottom variant="h4" style={heading}>
            PROJECT REPORT FOR<br/>
           {data.name}
            
          
        </Typography>

        <Typography gutterBottom variant="h4" style={heading}>
           {data.start_date}<br/>
            HH : MM : SS 
            
          
        </Typography>

        
      </CardContent>
     
    </RootStyle>
  );
}
