import http from 'src/adapters/http/http';

export function getProjectTypes() {
  return http.get(`v1/project-types`).then((response) => {
    console.log(response);
    return response;
  });
}

export function getProjectsByTypeID(project_type_id) {
  return http.get(`v1/getProjectsByProjectType/${project_type_id}`).then((response) => {
    console.log(response);
    return response;
  });
}
