import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Grid, Box, Card, CardContent } from '@mui/material';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
import { useState,useEffect } from 'react';

function Basics({data}) {

 
    const heading = {
        color: '#0c8b37',
        fontSize: '22px',
        paddingBottom:'12px',
        paddingTop:'50px',
    
      };

      const boxstyle = {
        padding:'20px',
        background:'#f9f7f7',
        borderRadius:'8px',
        display:'flex',
        alignItems:'center'
        
      };

  return (
    <div>
         <Typography gutterBottom variant="h4" style={heading}>
            PROJECT BASICS
        </Typography>
        <Grid container>
              <Grid item xs={6} md={2} lg={2}>
                <Box sx={{ paddingTop:'20px',paddingBottom:'20px', }}>
                <Typography variant='body' sx={{fontWeight:'600'}}>
                    Project Name<br/>
                    Project Category<br/>
                    Project Code<br/>
                    Start Date<br/>
                    End Date<br/>
                    Last Update Date<br/>
                    Project Administrator<br/>
                    Project Location<br/>

                </Typography>
                </Box>
            </Grid>

            <Grid item xs={6} md={4} lg={4}>
                <Box style={boxstyle}>
                <Typography variant='body'>
                {data.name}<br/>
                {data.project_type.title}<br/>
                PRM-ZX4U<br/>
                {data.start_date}<br/>
                {data.end_date}<br/>
                 June 02 2022<br/>
                {data.team_leader.name}<br/>
                {data.subcity.name} / Addis Ababa / Ethiopia<br/>

                </Typography>
                </Box>
            </Grid>
        </Grid>

    </div>
  )
}

export default Basics