import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Stack,Grid, Box, Card, CardContent,Table,TableBody,TableCell,TableContainer,TableHead,TableRow } from '@mui/material';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
import { useState,useEffect } from 'react';

function Cheats({data}) {
    const heading = {
        color: '#b51827',
        fontSize: '22px',
        paddingBottom:'12px',
        paddingTop:'50px',
    
      };

      const headingTwo = {
        color: '#b51827',
        fontSize: '22px',
        fontWeight:'200',
        textAlign:'center',
       
    
      };
      const numberTwo = {
        color: '#b51827',
        fontSize: '61px',
        
    
        
        
    
      };

      const boxstyle = {
        padding:'20px',
        background:'#f9f7f7',
        borderRadius:'8px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        
        
      };

   
  return (
    <div>
        <Typography gutterBottom variant="h4" style={heading}>
        REPORTED CHEATS
        </Typography>

        <Grid container spacing={2}>
    
  <Grid item xs={4} md={4} lg={4} sx={{ display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column"}}>
  
  <Box style={boxstyle} sx={{ width:'100%', height:'100%'}}>
 
      <Typography gutterBottom variant="h4" style={headingTwo}>
      TOTAL CHEATS


            
      </Typography>
      <Typography gutterBottom variant="h1" style={numberTwo}>
      {data.length}
      </Typography>
  
      </Box>
  
  </Grid>
  <Grid item xs={8} md={8} lg={8} sx={{ display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column"}}>

  <Box style={boxstyle} sx={{ width:'100%', height:'100%'}}>
  <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell> Milestone </TableCell>
                            <TableCell> Name </TableCell>
                            <TableCell> Date </TableCell>
                            <TableCell> Time </TableCell>
                            <TableCell> Staff </TableCell>
                        
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((cheat) => (
                        <TableRow>
                            <TableCell> 2 </TableCell>
                            <TableCell> Task </TableCell>
                            <TableCell> Staff </TableCell>
                            <TableCell> hr:min </TableCell>
                            <TableCell> Staff </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
</Grid>
  </Grid>
    </div>
  )
}

export default Cheats