import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import UserCreate from './pages/UserCreate';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import ProjectCategory from './pages/ProjectCategory';
import ProjectDetail from './pages/ProjectDetail';
import ChallengeDetail from './pages/ChallengeDetail';
import ProjectDetailTimeLine from './pages/ProjectDetailTimeLine';
import ProjectCreate from './pages/ProjectCreate';
import ProjectSubcity from './pages/ProjectSubcity';
import TaskUpdate from './pages/TaskUpdate';
import ProjectTypeBySubcity from './pages/ProjectTypeBySubcity';
import ProjectCategoryList from './pages/ProjectCategoryList';
import Report from './pages/Report';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout/>,
      children: [
        { path: '/dashboard', element: <Navigate to="/dashboard/app" /> },
        { path: 'app', element: <DashboardApp />, },
        { path: 'user', element: <User /> },
        { path: 'subcity/:id/project-type', element: <ProjectTypeBySubcity/> },
        { path: 'subcity/:id/project-type/:project_type_id', element: <ProjectSubcity/> },
        { path: 'user/new',element: <UserCreate/>},
        { path: 'projectnew',element: <ProjectCreate/>},
        { path: 'taskupdate',element: <TaskUpdate/>},
        { path: 'projects',element: <ProjectCategoryList/>},
        { path: 'projectcategory/:id', element: <ProjectCategory /> },
        { path: 'projectdetail/:id', element: <ProjectDetail/> },
        { path: 'challengedetail/:id', element: <ChallengeDetail/> },
        { path: 'timeline/:id', element: <ProjectDetailTimeLine/> },
        { path: 'blog', element: <Blog /> },
        { path: 'report/:id', element: <Report /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
