import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// material
import { alpha, styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import hoverEffect from 'hover-effect'

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  Link,
  Button,
  LinearProgress,
  TableHead,
  TableContainer,
  Box,
  Tab,
  Card,
  Grid,
  Divider,
  Skeleton,
  Container,
  Typography,
} from '@mui/material';
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
} from '@mui/lab';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummaryCat,
  ProjectWelcome,
  AppCurrentSubject,
  AppConversionRates,
  ChallengeWelcomeBox,
  ProjectDetailedBox,
  ProjectTimelineWelcomeBox,
} from '../sections/@dashboard/app';

const LABEL = ['Pending', 'Cancel', 'Done'];

import { getProjectDetail } from 'src/adapters/data/project';
import { randomColor } from '../utils/colors';

export default function ProjectDetailTimeLine() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  
  const [project, setProject] = useState({
    id: '',
    name: '',
    description: '',
  });

  var myAnimation = new hoverEffect({
    parent: document.querySelector('.my-div'),
    intensity: 0.3,
    image1: 'images/myImage1.jpg',
    image2: 'images/myImage2.jpg',
    displacementImage: 'images/myDistorsionImage.png'
});

  const [reports, setReport] = useState([]);
  //const morph = useMorph();
  useEffect(() => {
    getProjectDetail(id).then((res) => {
      setProject(res.data.data);
      setReport(res.data.data.reports);
      setLoading(false);
    });
  }, []);

  const projectImage = {
    borderRadius: 10,
    width: '50%',
    height: '100px',
    objectFit: 'cover',
  };

  return (
    <Page title="Project Details">
      {!loading && (
        <Container maxWidth={'lg'}>
          <ProjectTimelineWelcomeBox projectName={project.name} />
          <br />
          <br />

          <Grid container>
            <Grid item xs={12} md={8} lg={8}>
              <Timeline>
                {reports.map((report, index) => (
                  <TimelineItem>
                    <TimelineOppositeContent color={randomColor(index)}>
                      {report.date} | {report.time}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color={randomColor(index)} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {report.answer && <FlagCircleIcon color="error" />}
                      <Stack direction="row" spacing={1}>
                        <img src="/static/project.jpg" alt="project" style={projectImage} />
                        <img src="/static/project.jpg" alt="project" style={projectImage} />
                        <img src="/static/project.jpg" alt="project" style={projectImage} />
                      </Stack>

                      <br />
                      <Typography gutterBottom variant="p">
                        {project.subcity.name},Addis Ababa <br />
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
