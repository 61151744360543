import React from 'react'
import { Box,Grid,Typography } from '@mui/material'

function Photos() {


    const heading = {
        color: '#0c8b37',
        fontSize: '22px',
        paddingBottom:'12px',
        paddingTop:'50px',
    
      };

     const headingTwo = {
        color: '#0c8b37',
        fontSize: '22px',
        fontWeight:'200',
        textAlign:'center',
       
    
      };
      const numberTwo = {
        color: '#0c8b37',
        fontSize: '61px',
        
    
        
        
    
      };

      const boxstyle = {
        padding:'20px',
        background:'#f9f7f7',
        borderRadius:'8px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        
        
      };

      
  return (
    <div>
        
        <Typography gutterBottom variant="h4" style={heading}>
        PHOTOS TAKEN
        </Typography>

        <Grid container spacing={2}>
    
  <Grid item xs={4} md={4} lg={4} sx={{ display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column"}}>
  
  <Box style={boxstyle} sx={{ width:'100%', height:'100%'}}>
 
      <Typography gutterBottom variant="h4" style={headingTwo}>
      TOTAL PHOTOS

            
      </Typography>
      <Typography gutterBottom variant="h1" style={numberTwo}>
      83
      </Typography>
  
      </Box>
  
  </Grid>
  <Grid item xs={8} md={8} lg={8} sx={{ display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column"}}>
  <Box style={boxstyle} sx={{ width:'100%', height:'100%'}}>
  
  </Box>
  </Grid>
</Grid></div>
  )
}

export default Photos