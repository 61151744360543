import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Grid, Box, Card, CardContent } from '@mui/material';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
import { useState,useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


function LayeredProgressbar(props) {
    const { renderOverlays, ...otherProps } = props;
    const overlayStyles = {
      position: "absolute",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    };
    const overlays = props.renderOverlays();
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%"
        }}
      >
        <div style={{ position: "absolute" }}>
          <CircularProgressbar {...otherProps} text="" value={props.percentage} textForPercentage={null} strokeWidth="10" />
        </div>
        {overlays.map(overlay => (
          <div style={overlayStyles}>{overlay}</div>
        ))}
      </div>
    );
  }


  function RadialSeparator(props) {
    return (
      <div
        style={{
          background: "#f9f7f7",
          width: "3px",
          height: "100%",
          transform: `rotate(${props.degrees}deg)`
        }}
      />
    );
  }


  function generateRadialSeparators(numSeparators) {
    const degrees = 360 / numSeparators;
    return _.range(numSeparators / 2).map(index => (
      <RadialSeparator degrees={index * degrees} />
    ));
  }


function ProgressTwo({percentage}) {
    const heading = {
        color: '#0c8b37',
        fontSize: '22px',
        paddingBottom:'12px',
        paddingTop:'50px',
    
      };

      const boxstyle = {
        padding:'20px',
        background:'#f9f7f7',
        borderRadius:'8px',
        display:'flex',
        alignItems:'center'
        
      };
  return (
    <div>
     
        <div
      style={{
        width: "140px",
        height: "140px"
      }}
    >
              <LayeredProgressbar
        percentage={percentage}
        styles={{
          path: {
            stroke: `rgb(12, 139, 55)`,
            strokeLinecap: "butt"
          }
        }}
        renderOverlays={() =>
          generateRadialSeparators(4).concat(
                      )
        }
      />
      </div>
     
    </div>
  )
}

export default ProgressTwo