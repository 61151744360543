// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [

  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
 
  {
    title: 'Projects',
    path: '/dashboard/projects',
    icon: getIcon('eva:file-text-fill'),
  },

  {
    title: 'Create Project',
    path: '/dashboard/projectnew',
    icon: getIcon('eva:file-text-fill'),
  },


  {
    title: 'Project Report',
    path: '/dashboard/report',
    icon: getIcon('eva:file-text-fill'),
  },
  
];

export default navConfig;
