import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Card, CardContent } from '@mui/material';


// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: 'rgb(200, 250, 205)',
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

// ----------------------------------------------------------------------

// ProjectTimelineWelcomeBox.propTypes = {
//   displayName: PropTypes.string
// };

export default function ProjectTimelineWelcomeBox({projectName}) {

    const prjpercentage = {
        color: "rgb(0, 82, 73)",
        fontSize:"100px",
        position:"absoule",
        bottom:"-5px",
      };

      const wrapper = {
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        justifyContent:"end",
        height:"100%" 
      };

  return (
    <RootStyle>
      <CardContent style={wrapper}
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: 'grey.800'
        }}
      >
    
        <Typography gutterBottom variant="h4">
           {projectName}
        </Typography>

        <Typography gutterBottom variant="h4" style={prjpercentage}>
        Progress
        </Typography>

    </CardContent>
    <img src="/static/illustrations/welcome_woman.png" alt="welcome" />

      
    </RootStyle>
  );
}
