import React, {Suspense, useContext, useState } from 'react';

import MapPicker from 'react-google-map-picker';

import { ProjectCreateStateContext } from 'src/pages/ProjectCreate';

export default function MapSelector({data,setter,initialLocation}) {

  const DefaultLocation = initialLocation || {  lat: 9.033021, lng: 38.763223  };
  const DefaultZoom =12;

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const projectDataState = useContext(ProjectCreateStateContext);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);

  function handleChangeLocation(lat, lng) {
    setLocation({ lat, lng });

    setter({
      ...data,
      location_lat: lat,
      location_long: lng
    });

  
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }
  
  return (
    <>
    <Suspense fallback={<div>Loading...</div>}>
        <MapPicker
          defaultLocation={defaultLocation}
          zoom={zoom}
          style={{ height: '300px' }}
          onChangeLocation={handleChangeLocation}
          onChangeZoom={handleChangeZoom}
          apiKey="AIzaSyAkBhTU6Tc8FNdu64ZRG4rPm2bin7H7OOI"
        />
    </Suspense>
    </>
  );
}
