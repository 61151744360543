export const convertValueToStatus = (value) => {
  //if a value is less than 25, return bad status
  if (value <= 25) {
    return 'Bad';
  }
  //if a value is more than 25 but less than 50 return warning status
  if (value > 25 && value <= 50) {
    return 'In danger';
  }

  //if a value is more than 50 but less than 75 return info status
  if (value > 50 && value < 75) {
    return 'Good';
  }

  //if a value is more than 75 but less than 100 return success status
  if (value > 75 && value < 100) {
    return 'Vgood';
  }
};
