import http from 'src/adapters/http/http';

export function getUserList() {
  return http.get('v1/user').then((response) => {
    return response;
  });
}

export function getUserDetail(id) {
  return http.get('v1/user/' + id).then((response) => {
    return response;
  });
}
