import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Grid, Box, Card, CardContent } from '@mui/material';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
import { useState,useEffect } from 'react';

function Description({data}) {
  
    const heading = {
        color: '#0c8b37',
        fontSize: '22px',
        paddingBottom:'12px',
        paddingTop:'50px',
    
      };

      const boxstyle = {
        padding:'20px',
        background:'#f9f7f7',
        borderRadius:'8px',
        display:'flex',
        alignItems:'center'
        
      };
  
    return (


    <div>
  <Grid container>
        <Grid item xs={7} md={7} lg={7}>
        <Typography gutterBottom variant="h4" style={heading}>
           PROJECT DESCRIPTION 
        </Typography>
       
                <Box style={boxstyle}>
                <Typography variant='body'>
                {data.description}

                </Typography>
                </Box>
            </Grid>
            <Grid item xs={1} md={1} lg={1}></Grid>
            <Grid item xs={4} md={4} lg={4} >
            <Typography gutterBottom variant="h4" style={heading}>
            PROJECT QR CODE 
            </Typography>

            </Grid>
        </Grid>
    </div>
  )
}

export default Description