import * as Yup from 'yup';
import { useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
// material
import { styled } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Delete, RemoveCircleOutline } from '@material-ui/icons';
import { createProject, getProjectOptions } from 'src/adapters/data/project';
import MapSelector from 'src/components/MapSelector';
import { AppStateContext } from 'src/App';
import { useContext } from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';

import {
  Card,
  Chip,
  Grid,
  Stack,
  Switch,
  Select,
  TextareaAutosize,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  Autocomplete,
  Container,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  Radio,RadioGroup,FormLabel

} from '@mui/material';


import Page from '../components/Page';
import Loading from './Loading';



export default function TaskUpdate({ isEdit, currentProduct }) {
    const [loading, SetLoading] = useState(false);


    const [value, setValue] = React.useState('Started');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

return (
<Page title="Project">
      {!loading ? (
        <Container maxWidth={'lg'} >
          <Typography variant="h4" gutterBottom>
                    Project title
          </Typography>

       
          

          <form>
            <Grid container spacing={3}>
              

              <Grid xs={12} md={12}>
                <br />
                <br />
                <Card sx={{ p: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Milestone
                  </Typography>

                  
                    <>
                      <Stack spacing={2} marginTop={3} direction="row">
                        <TextField
                          label="Milestone Name"
                    
                        />
                        <TextField
                          label="Milestone Description"
                          
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Start Date"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="End Date"
                        
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                       
                         
                  
                        {/* Buton with icon */}
                      </Stack>

                    
                          <Stack spacing={2} margin={3} direction="row">
                            <TextField
                              
                              label="Task Name"
                              
                            />
                            <TextField
                              
                              label="Task Description"
                              
                            />


<FormControl>
      <RadioGroup
    
        row
        aria-labelledby="task status"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        
        <FormControlLabel value="Started" control={<Radio />} label="Started" />
        <FormControlLabel value="Pending" control={<Radio />} label="Pending" />
        <FormControlLabel value="Closed" control={<Radio />} label="Closed" />
      
      </RadioGroup>
    </FormControl>
                            
                            
                          
                          </Stack>


                          <Stack spacing={2} margin={3} direction="row">
                            <TextField
                              
                              label="Task Name"
                              
                            />
                            <TextField
                              
                              label="Task Description"
                              
                            />


<FormControl>
      <RadioGroup
    
        row
        aria-labelledby="task status"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        
        <FormControlLabel value="Started" control={<Radio />} label="Started" />
        <FormControlLabel value="Pending" control={<Radio />} label="Pending" />
        <FormControlLabel value="Closed" control={<Radio />} label="Closed" />
      
      </RadioGroup>
    </FormControl>
                            
                            
                          
                          </Stack>
                        
                     
                    </>
                
                </Card>
              </Grid>
            </Grid>
          </form>
        </Container>
      ) : (
        <Loading />
      )}
    </Page>
);
}