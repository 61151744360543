import React from 'react';
import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

function Tasks({ tasks,basic }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell> Milestone </TableCell>
            <TableCell> Name </TableCell>
            <TableCell> Responsibility </TableCell>
            <TableCell> Date </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task,index) => {
            return (
              <TableRow>
                <TableCell> {task.milestone} </TableCell>
                <TableCell> {task.title} </TableCell>
                <TableCell> {basic.team_leader.name} </TableCell>
                <TableCell> {basic.end_date} </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Tasks;
