import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import Heading from 'src/components/report/Heading';
import { jsPDF } from 'jspdf';
import Basics from 'src/components/report/Basics';
import { Box, Button } from '@mui/material';
import Description from 'src/components/report/Description';
import Stakeholders from 'src/components/report/Stakeholders';
import Staff from 'src/components/report/Staff';
import ProgressOne from 'src/components/report/ProgressOne';
import OverallProgress from 'src/components/report/OverallProgress';
import CPTasks from 'src/components/report/CPTasks';
import Photos from 'src/components/report/Photos';
import Challenge from 'src/components/report/Challenge';
import Reaming from 'src/components/report/Reaming';
import Cheats from 'src/components/report/Cheats';
import FooterLogo from 'src/components/report/FooterLogo';
import { useParams } from 'react-router-dom';
import './ReportPrint.css';

import { useReactToPrint } from 'react-to-print';
import { getReportPdf } from '../adapters/data/reportPDF';
import Loading from './Loading';

function Report() {
  const reportComponent = useRef();

  const { id } = useParams();

  const [data, setData] = useState(false);

  const [displayOptions, setDisplayOptions] = useState({
    basics: true,
    description: true,
    stakeholders: true,
    staff: true,
    progressOne: true,
    overallProgress: true,
    cptasks: true,
    photos: true,
    challenge: true,
    reaming: true,
    cheats: true,
  });

  const getReportForPdf = async () => {
    const response = await getReportPdf(id);
    setData(response.data.data);
  };

  useEffect(() => {
    getReportForPdf();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => reportComponent.current,
  });

  const downloadFileDocument = () => {
    const input = document.getElementById('pdfdoc');

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      //fit to page and center horizontally
      console.log(pdf.internal.pageSize.getWidth());
      pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());

      pdf.save('test.pdf');
    });

    // html2canvas(input).then((canvas)=>{
    //     const imgData=canvas.toDataURL("image/png");
    //     const imgWidth = 190;
    //         const pageHeight = 290;
    //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //         let heightLeft = imgHeight;

    //     const pdf=new jsPDF("pt","mm");
    //     let position = 0;

    //     pdf.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
    //     heightLeft -= pageHeight;
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
    //       heightLeft -= pageHeight;
    //   }

    //     pdf.save("report")

    // })
  };

  if (!data) {
    return <Loading />;
  } else {
    return (
      <div>
        <Button onClick={handlePrint}>Download Report</Button>
        <div id="pdfdoc">
          <Box ref={reportComponent} sx={{ width: '100%', padding: '30px' }}>
            <div className="print-container" style={{ margin: '0', padding: '0' }}>
              <>
                <div className="page-break" />
                <div className="hideReportforDesktop">
                  <Heading data={data.basic} />
                  <Basics className="avoidPageBreakInside" data={data.basic} />
                   <div className="page-break" />
                  {displayOptions.description && <Description data={data.basic} />}
                  <div className="page-break" />
                  {displayOptions.stakeholders && <Stakeholders data={data.basic} />}
                  <div className="page-break" />
                  {displayOptions.staff && <Staff data={data.basic} />}
                  {displayOptions.progressOne && <ProgressOne data={data.milestones} />}
                  <div className="page-break" />
                  <OverallProgress data={data.basic} milestones={data.milestones} basic={data.basic} tasks={data.tasks}  />
                  <div className="page-break" />
                  <CPTasks data={data.tasks} basic={data.basic} />
                  <div className="page-break" />
                  <Photos />
                  <div className="page-break" />
                  {displayOptions.challenge && <Challenge />}
                  <div className="page-break" />
                  {displayOptions.reaming && <Reaming tasks={data.tasks} basic={data.basic} />}
                  <div className="page-break" />
                  {displayOptions.cheats && <Cheats data={data.cheats}/>}
                  <FooterLogo />
                </div>
              </>
            </div>
          </Box>
        </div>
      </div>
    );
  }
}

export default Report;
