import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Card,Link,
    Button, CardContent } from '@mui/material';
    import CardActionArea from '@mui/material/CardActionArea';
    import { Link as RouterLink, useParams } from 'react-router-dom';


// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

// ----------------------------------------------------------------------

ProjectDetailedBox.propTypes = {
  displayName: PropTypes.string
};

export default function ProjectDetailedBox() {

    const { id } = useParams();

      const wrapper = {
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        justifyContent:"end",
        height:"100%" 
      };

  return (
    <RootStyle>
    
      <CardContent style={wrapper}
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: 'grey.800'
        }}
      >
    
        <Typography gutterBottom variant="h4">
           See Detailed Progress
         
        </Typography>
        
        <Button variant="contained"  component={RouterLink}  to={"/dashboard/timeline/"+id} >
          Click Here
        </Button>
    

    </CardContent>

    <img src="/static/illustrations/welcome_woman.png" alt="welcome" />

      
    </RootStyle>
  );
}
