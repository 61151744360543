import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Card, CardContent } from '@mui/material';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
import { useState,useEffect } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

// ----------------------------------------------------------------------

ProjectWelcome.propTypes = {
  displayName: PropTypes.string,
};

export default function ProjectWelcome({title,progress}) {


  const prjpercentage = {
    color: 'white',
    fontSize: '100px',
    position: 'absoule',
    bottom: '-5px',
  };

  const wrapper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'end',
    height: '100%',
  };

  return (
    <RootStyle>
      <CardContent
        style={wrapper}
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: 'grey.800',
        }}
      >
        <Typography gutterBottom variant="h4">
          {title}
        </Typography>

        <Typography gutterBottom variant="h4" style={prjpercentage}>
         {progress}%
        </Typography>
      </CardContent>
      <img src="/static/illustrations/welcome_woman.png" alt="welcome" />
    </RootStyle>
  );
}
