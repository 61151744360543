import { Link as RouterLink, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { useState, useEffect } from 'react';
import { getProjectsBySubcity } from 'src/adapters/data/project';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppTrafficBySite,
  AppWidgetSummaryCat,
  ProjectWelcome,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { randomColor } from 'src/utils/colors';

import Loading from './Loading';
import { getProjectTypeBySubcity } from 'src/adapters/data/project';

export default function ProjectTypeBySubcity() {
  const theme = useTheme();
  //get id from url
  const { id, project_type_id } = useParams();

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProjectSubcity = async () => {
    const response = await getProjectsBySubcity(id, project_type_id);
    setProjects(response.data.data);
    setLoading(false);
  };

  const formatGraphData = (projectGraph) => {
    let data = [];
    projectGraph.forEach((item) => {
      data.push({
        label: item.project_name,
        value: item.total_progress,
      });
    });

    return data;
  };

  useEffect(() => {
    getProjectSubcity();
  }, []);

  return (
    <Page title="Project Dashboard">
      {loading ? (
        <Loading />
      ) : (
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <ProjectWelcome title={projects[0].project_name} progress={projects[0].total_progress } />
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppConversionRates title="Projects" subheader="this year" chartData={formatGraphData(projects)} />
          </Grid>

           {projects.map(project => {
            return (
              <Grid key={project.project_id} sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title={project.project_name}
                  total={project.total_progress}
                  icon={'ant-design:android-filled'}
                  link={"/dashboard/projectdetail/"+project.project_id}
                 
                />
              </Grid>
            );
          })} 
        </Container>
      )}
    </Page>
  );
}
