import axios from 'axios';
// import { showErrorToast } from 'src/Services/notificationService';

axios.interceptors.response.use(null, (error) => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

  if (!expectedError) {
    // showErrorToast(error.message);
    //console.log(error);
  }

  return Promise.reject(error);
});

function setJwt(jwt = '') {
  axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  axios.defaults.headers.common.AccessControlAllowOrigin = '*';
 
  //base url for api
  // axios.defaults.baseURL = 'http://localhost:8001/api/';
axios.defaults.baseURL= 'https://green.natnaelghirma.com/api/';

}

setJwt(localStorage.getItem('token'));

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};

export default http;
