import React from 'react'
import { Typography, Stack,Grid, Box, Card, CardContent } from '@mui/material';

import "./verticalProgress.css";

const VerticalProgress = ({ progress,progressText }) =>{
  return (
    
    <div>
    
   
      <div className="progress">
      <Typography className='green' gutterBottom variant="body">
    {progress}%
</Typography>
      <div className='progressInner vertical'>
        <div style={{ height: `${progress}%` }} className="progress-bar">
        </div>
        </div>
        <Typography gutterBottom variant="body">
    {progressText}
</Typography>
      </div>
      
</div>
      
  
  )
}

export default VerticalProgress