import * as React from 'react';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import './Gantt.css'

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  Button,
  LinearProgress,
  CircularProgress,
  TableHead,
  TableContainer,
  Box,
  Tab,
  Card,
  Grid,
  Divider,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummaryCat,
  ProjectWelcome,
  AppCurrentSubject,
  AppConversionRates,
  ProjectDetailedBox,
} from '../sections/@dashboard/app';

import { getProjectDetail } from '../adapters/data/project';
import Loading from './Loading';

// sections
import { AppFeatured } from '../sections/@dashboard/app';
import { convertColorText, convertedColor } from 'src/utils/colors';
import { convertLength } from '@mui/material/styles/cssUtils';
import { convertValueToStatus } from 'src/utils/status';

const LABEL = ['Pending', 'Cancel', 'Done'];

let tasks = [
  {
    start: new Date(2020, 1, 1),
    end: new Date(2020, 1, 2),
    name: 'Task 1',
    id: 'Task 0',
    type: 'task',
    progress: 45,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
  },
];

export default function ProjectDetail() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { id } = useParams();
  //const { name } = useParams();
  const [value, setValue] = useState('1');

  const [project, setProject] = useState({
    name: '',
    description: '',
    location_lat: '',
    location_long: '',
    milestones: [],
    challenges: [],
    reports: [],
    team_leader: {
      id: '',
      name: '',
    },
    status: '',
  });

  const [loading, setLoading] = useState(true);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const getProject = async () => {
    const response = await getProjectDetail(id);

    setLoading(false);

    setProject(response.data.data);
  };

  useEffect(() => {
    getProject();
  }, []);

  const projectImage = {
    borderRadius: 10,
    width: '100%',
    height: '400px',
    objectFit: 'cover',
  };

  const projectDataTop = {
    padding: '60px',
  };

  const projectTop = {
    padding: '20px',
  };
  const projectimage = {
    margin: '20px',
  };
  return !loading ? (
    <Page title="Project Details">
      <Container maxWidth={'lg'}>
        <>
          <Card>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <Box style={projectimage}>
                  <AppFeatured />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={projectDataTop}>
                <Typography gutterBottom variant="h4">
                  {project.name}
                </Typography>

                <Stack spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Typography gutterBottom variant="subtitle2">
                    Start Date
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    {project.start_date}
                  </Typography>
                </Stack>

                <Stack spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Typography gutterBottom variant="subtitle2">
                    End Date
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    {project.end_date}
                  </Typography>
                </Stack>


                <Stack spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Typography gutterBottom variant="subtitle2">
                    Last updated on
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    June 02 2022
                  </Typography>
                </Stack>

                <Stack spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
                  <Typography gutterBottom variant="subtitle2">
                    Responsible Person
                  </Typography>
                  <Avatar alt="Tekele Bekele" src="/static/mock-images/avatars/avatar_1.jpg" />
                  <Typography gutterBottom variant="body2">
                    {project.team_leader.name}
                  </Typography>
                </Stack>

                <Typography gutterBottom variant="h5">
                  Addis Ababa
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </>

        <br />
        <br />

        <Card>
          <TabContext value={value}>
            <Box sx={{ px: 3, bgcolor: 'background.neutral' }}>
              <TabList onChange={handleChangeTab}>
                <Tab disableRipple value="1" label="Milestones" />
                <Tab disableRipple value="2" label="Reports" />
                <Tab disableRipple value="3" label="Milestone progress" />
              </TabList>
            </Box>

            <Divider />

            <TabPanel value="1">
              <Box sx={{ p: 3 }}>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 720 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Mile Stones</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {project.milestones.map((milestone) => {
                          return (
                            <TableRow key={milestone.milestone_id}>
                              <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{ ml: 2 }}>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                      {milestone.milestone_name}
                                    </Typography>
                                    {/* <Typography variant="subtitle2"> {milestone.description}</Typography> */}
                                  </Box>
                                </Box>
                              </TableCell>

                              <TableCell>
                                <Typography variant="subtitle2">{milestone.end_date}</Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  {milestone.deadline}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Label
                                  variant={'filled'}
                                  color={milestone.total_progress < 100 ? 'warning' : 'success'}
                                >
                                  {sentenceCase(milestone.total_progress < 100 ? 'in_progress' : 'completed')}
                                </Label>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Box>
            </TabPanel>

            <TabPanel value="2">
              <Box sx={{ p: 3 }}>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 720 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {project.reports.map((report) => {
                          return (
                            <TableRow key={report.id}>
                              <TableCell>
                                <Typography variant="subtitle2">{report.date}</Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  {report.time}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                {report.answer != null && (
                                  <Label
                                    sx={{ mr: 2 }}
                                    variant={'filled'}
                                    color={
                                      ('status' === 'completed' && 'success') ||
                                      ('completed' === 'in_progress' && 'warning') ||
                                      'error'
                                    }
                                  >
                                    {sentenceCase('Challenge')}
                                  </Label>
                                )}

                                {report.cheated == true && (
                                  <Label
                                    variant={'filled'}
                                    color={
                                      ('status' === 'completed' && 'success') ||
                                      ('completed' === 'in_progress' && 'warning') ||
                                      'warning'
                                    }
                                  >
                                    {sentenceCase('Cheated')}
                                  </Label>
                                )}
                              </TableCell>

                              <TableCell>
                                <Button
                                  variant="outlined"
                                  component={RouterLink}
                                  to={'/dashboard/challengedetail/' + report.id}
                                >
                                  More
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Box>
            </TabPanel>

            <TabPanel value="3">
              {/* <Stack spacing={3} sx={{ px: 3, my: 5 }}>
                {project.milestones.map((milestone) => {
                  return (
                    <Card key={milestone.id}>
                      <CardHeader title={milestone.milestone_name} />
                      <Stack direction={'row'} sx={{ px: 3, my: 5 }}>
                        {milestone.tasks.map((task, taskIndex) => {
                          return (
                            <>
                              <LinearProgress
                                style={{
                                  width: `${(1 / milestone.tasks.length) * 100}%`,
                                  marginLeft: '5px',
                                }}
                                variant="determinate"
                                value={task.status == 'closed' ? 100 : 0}
                                color="primary"
                                sx={{ height: 4, bgcolor: 'grey.50016', borderRadius: 8 }}
                                title={`${task.title}`}
                              />
                            </>
                          );
                        })}
                      </Stack>

                      <FrappeGantt
                        tasks={[
                          {
                            id: 'Task 1',
                            name: 'Task 1',
                            start: '2018-12-05',
                            end: '2018-12-12',
                            progress: 40,
                            dependencies: '',
                            color: '#222',
                          },
                          {
                            id: 'Task 2',
                            name: 'Task 2',
                            start: '2018-12-10',
                            end: '2018-12-15',
                            progress: 50,
                            dependencies: 'Task 1',
                          },
                          {
                            id: 'Task 3',
                            name: 'Task 3',
                            start: '2018-12-11',
                            end: '2018-12-15',
                            progress: 50,
                            dependencies: 'Task 1',
                          },
                          {
                            id: 'Task 4',
                            name: 'Task 4',
                            start: '2018-12-13',
                            end: '2018-12-15',
                            progress: 100,
                            dependencies: 'Task 1',
                          },
                          {
                            id: 'Task 5',
                            color: '#ff0000',
                            name: 'Task 5',
                            start: '2018-12-08',
                            end: '2018-12-20',
                            progress: 0,
                          },
                        ]}
                        // viewMode={this.state.mode}
                         onClick={() => console.log("testing")}
                        // onDateChange={(task, start, end) => console.log(task, start, end)}
                        // onProgressChange={(task, progress) => console.log(task, progress)}
                        // onTasksChange={(tasks) => console.log(tasks)}
                      />
                    </Card>
                  );
                })}
              </Stack> */}

              {project.milestones.map((milestone) => {
                let taskV = [];
                milestone.tasks.map((task) => {
                  taskV.push({
                    start: new Date(2020, 1, 1),
                    end: new Date(2020, 1, Math.floor(Math.random() * 30) + 1),
                    name: task.title,
                    id: task.id,
                    type: 'task',
                    progress: task.status == 'closed' ? 100 : 0,
                    isDisabled: false,
                    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
                  });
                });

                return (
                  <Accordion
                    expanded={expanded === milestone.milestone_id}
                    onChange={handleChange(milestone.milestone_id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Stack direction="column" sx={{ width: '100%' }}>
                        <Typography sx={{ pb: '6', textTransform: 'capitalize' }}>
                          {milestone.milestone_name}
                        </Typography>
                        <Stack direction="row" sx={{ width: '100%', pr: '3' }}>
                          {milestone.tasks.map((task) => {
                            return (
                              <LinearProgress
                                style={{ width: `${(1 / milestone.tasks.length) * 100}%`, marginRight: '20px' }}
                                variant="determinate"
                                key={task.id}
                                value={task.status == 'closed' ? 100 : 0}
                                color={convertedColor(milestone.total_progress)}
                                sx={{ height: 8, bgcolor: 'grey.50016', borderRadius: 8 }}
                              />
                            );
                          })}
                        </Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Gantt tasks={taskV}  viewMode={ViewMode.Week}/>
                    </AccordionDetails>
                  </Accordion>
                );
              })}

              {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel1bh-header">
                  <Stack direction="column" sx={{ width: '100%', p: '6' }}>
                    <Typography sx={{ pb: '6' }}>Milestone 2</Typography>
                    <LinearProgress
                      variant="determinate"
                      key=""
                      value="33"
                      color="primary"
                      sx={{ height: 8, bgcolor: 'grey.50016', borderRadius: 8 }}
                    />
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Gantt tasks={tasks} />
                </AccordionDetails>
              </Accordion> */}

              <Stack direction="row" spacing={15}>
                {project.milestones.map((milestone) => {
                  return (
                    <Box sx={{ pt: 6, textAlign: 'center' }}>
                      <CircularProgressWithLabel
                        color={convertedColor(milestone.total_progress)}
                        value={milestone.total_progress}
                      />
                      <Typography>{milestone.milestone_name}</Typography>
                      <Typography color={convertColorText(milestone.total_progress)}>
                        {convertValueToStatus(milestone.total_progress)}
                      </Typography>
                    </Box>
                  );
                })}

                {/* <Box sx={{ pt: 6, textAlign: 'center' }}>
                  <CircularProgressWithLabel value={58} />
                  <Typography>MileStone 2</Typography>
                  <Typography color="secondary">good</Typography>
                </Box>

                <Box sx={{ pt: 6, textAlign: 'center' }}>
                  <CircularProgressWithLabel value={38} />
                  <Typography>MileStone 3</Typography>
                  <Typography color="warning">good</Typography>
                </Box>

                <Box sx={{ pt: 6, textAlign: 'center' }}>
                  <CircularProgressWithLabel value={28} />
                  <Typography>MileStone 4</Typography>
                  <Typography color="error">bad</Typography>
                </Box> */}
              </Stack>
            </TabPanel>
          </TabContext>
        </Card>

        <br />
        <br />
        <ProjectDetailedBox />
      </Container>
    </Page>
  ) : (
    <Loading />
  );
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={68} variant="determinate" color="primary" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body" component="p" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
