import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Grid, Box, Card, CardContent } from '@mui/material';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
import { useState,useEffect } from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

function Stakeholders({data}) {

  console.log(data);

    const heading = {
        color: '#0c8b37',
        fontSize: '22px',
        paddingBottom:'12px',
        paddingTop:'50px',
    
      };

      const boxstyle = {
        padding:'20px',
        background:'#f9f7f7',
        borderRadius:'8px',
        display:'flex',
        alignItems:'center'
        
      };
  return (
    <div>
         <Typography gutterBottom variant="h4" style={heading}>
         PROJECT STAKEHOLDERS
        </Typography>
        <Box style={boxstyle}>
        <Grid container>

            {data.stake_holders.map((stakeholder) => {
              return (
              <Grid key={stakeholder.id} item xs={3} md={3} lg={3} sx={{ display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column"}}>

              <AccountBalanceIcon color='primary' sx={{ fontSize:"100px" }}/> <br/>
              <Typography gutterBottom variant="body1" >
              {stakeholder.user.name}
              </Typography>


           </Grid>)
            })}

           
          
        </Grid>
        </Box>
    </div>
  )
}

export default Stakeholders