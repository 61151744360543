import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Grid, Box, Card, Avatar , CardContent } from '@mui/material';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
import { useState,useEffect } from 'react';
function Staff({data}) {

    const heading = {
        color: '#0c8b37',
        fontSize: '22px',
        paddingBottom:'12px',
        paddingTop:'50px',
    
      };

      const boxstyle = {
        padding:'20px',
        background:'#f9f7f7',
        borderRadius:'8px',
        display:'flex',
        alignItems:'center'
        
      };
  return (
    <div> <Typography gutterBottom variant="h4" style={heading}>
    PROJECT STAFF
   </Typography>
   <Box style={boxstyle}>
   <Grid container>
       



          {data.team_members.map((member) => {
            return (
              <Grid item xs={3} md={3} lg={3} sx={{ display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column"}} >
         <Avatar sx={{ bgcolor: "primary",width:61,height:61 }}>N</Avatar><br/>
                   <Typography gutterBottom variant="body1" 
                   sx={{
                    fontSize: "0.8em",
                   }}
                   >
                   {member.user.name}
                   </Typography>


         </Grid>
            )
          })}


   </Grid>
   </Box></div>
  )
}

export default Staff