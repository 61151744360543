import { faker } from '@faker-js/faker';
import { Link as RouterLink, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { useState, useEffect } from 'react';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppTrafficBySite,
  AppWidgetSummaryCat,
  ProjectWelcome,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { randomColor } from 'src/utils/colors';

import Loading from './Loading';

export default function ProjectCategory() {
  const theme = useTheme();
  //get id from url
  const { id } = useParams();



  const [projectType, setProjectype] = useState({});
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProjects = async () => {
    const response = await getProjectsByTypeID(id);

    setProjectype(response.data.data);
    setProjects(response.data.data.projects);
    setLoading(false);
  };

  const formatGraphData = (projectGraph) => {
    let data = [];
    projectGraph.forEach((item) => {
      data.push({
        label: item.project_name,
        value: item.total_progress,
      });
    });

    return data;
  };


  useEffect(() => {
    getProjects();
  }, []);

  return (
    
    <Page title="Project Dashboard">
      
      {loading? <Loading/> : <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <ProjectWelcome title={projectType.project_type_name} progress={projectType.total_progress} />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppConversionRates
              title="Project Status"
              subheader="this year"
              chartData={formatGraphData(projects)}
            />
          </Grid>

          {projects.map((project) => {
            return (
              <Grid key={project.project_name} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title={project.project_name}
                  total={project.total_progress}
                  icon={'ant-design:android-filled'}
                  link={'/dashboard/projectdetail'+'/'+project.project_id}
                />
              </Grid>
            );
          })}

          

        </Grid>
      </Container>}
    </Page>
  );
}
