import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Card, CardContent } from '@mui/material';
import AAMap from '../../../components/AAMap';
import ProjectDoneDs from '../../../components/ProjectDoneDs';


// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

// ----------------------------------------------------------------------

AppWelcome.propTypes = {
  displayName: PropTypes.string
};

export default function AppWelcome({ displayName,totalProgress,subcityProgress}) {
  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: 'grey.800'
        }}
      >
        <Typography gutterBottom variant="h4">
          Welcome back,
          <br /> {!displayName ? '...' : displayName}!
        </Typography>

    </CardContent>
    <img src="/static/illustrations/welcome_woman.png" alt="welcome" />

<AAMap subcityProgress = {subcityProgress}/>
<ProjectDoneDs totalProgress={totalProgress}/>
      
    </RootStyle>
  );
}
