import React from 'react';
import { Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

function Reaming({ tasks, basic }) {
  const heading = {
    color: '#0c8b37',
    fontSize: '22px',
    paddingBottom: '12px',
    paddingTop: '50px',
  };

  const boxstyle = {
    background: '#f9f7f7',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column' }}
        >
          <Typography gutterBottom variant="h4" style={heading}>
            REMAINING TASKS
          </Typography>
          <Box style={boxstyle} sx={{ width: '100%', height: '100%' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Milestone </TableCell>
                    <TableCell> Name </TableCell>

                    <TableCell> Responsibility </TableCell>
                    <TableCell> Date </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks
                    .filter((task) => {
                      return task.status == 'pending';
                    })
                    .map((task) => {
                      return (
                        <TableRow>
                          <TableCell> {task.milestone} </TableCell>
                          <TableCell> {task.title} </TableCell>
                          <TableCell> {basic.team_leader.name} </TableCell>
                          <TableCell> {task.start_date} </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Reaming;
