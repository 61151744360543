// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Card, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';

// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummaryCat.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummaryCat({ title, total, icon, link, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        py: 0,
        boxShadow: 0,
        textAlign: 'left',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <CardActionArea component={RouterLink} to={link}>
        <CardContent>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            {title}
          </Typography>

          <Typography variant="h3">{fShortenNumber(total)} %</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
