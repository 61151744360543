import { Link as RouterLink, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { useState, useEffect } from 'react';
import { getProjectTypes } from 'src/adapters/data/projectType';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppTrafficBySite,
  AppWidgetSummaryCat,
  ProjectWelcome,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { randomColor } from 'src/utils/colors';

import Loading from './Loading';

export default function ProjectCategoryList() {
  const theme = useTheme();

  const [projectTypes, setProjectype] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProjectTypeList = async () => {
    getProjectTypes()
      .then((response) => {
        setProjectype(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
       
      });
  };

  useEffect(() => {
    getProjectTypeList();
  }, []);

  return (
    <Page title="Project Cateogories Dashboard">
      {/* title */}
   
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Project Categories
            </Typography>
          </Grid>
        </Grid>
      
      {loading ? (
        <Loading />
      ) : (
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            {projectTypes.map(projectType=>{
              return (
                <Grid sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title={projectType.project_type_name}
                  total={projectType.total_progress}
                  icon={'ant-design:android-filled'}
                  link={"/dashboard/projectcategory/"+projectType.project_type_id}
                />
              </Grid>
              )
            })}
            
          
          </Grid>
        </Container>
      )}
    </Page>
  );
}
