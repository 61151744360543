import http from 'src/adapters/http/http';

export function login(email, password) {
  return http.post('login', { email, password }).then((response) => {
    return response;
  });
}

export function logout() {
  localStorage.clear();
}
