import http from 'src/adapters/http/http';

export function getProjectDetail(project_id) {
  return http.get(`v1/project/${project_id}`).then((response) => {
    console.log(response);
    return response;
  });
}

export function createProject(data) {
  return http.post('v1/project', data).then(response => {
    console.log(response);
    return response;
  });
}

export function getProjectOptions() {
  return http.get('v1/form-options/project').then((response) => {
    console.log(response);
    return response;
  });
}

export function getProjectTypeBySubcity(subcity_id) {
  return http.get(`v1/project-types/subcity/${subcity_id}`).then((response) => {
    console.log(response);
    return response;
  });
}

export function getProjectsBySubcity(subcity_id,project_type_id) {
  return http.get(`v1/project-types/subcity/${subcity_id}/${project_type_id}`).then((response) => {
    console.log(response);
    return response;
  });
}
