import React from 'react'
import { Grid,Box,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Typography } from '@mui/material'

function Challenge() {
    const heading = {
        color: '#0c8b37',
        fontSize: '22px',
        paddingBottom:'12px',
        paddingTop:'50px',
    
      };

      const boxstyle = {
      
        background:'#f9f7f7',
        borderRadius:'8px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        
        
      };

  return (
    <div>
        
        
        <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12} sx={{ display:"flex", justifyContent:"space-between", alignItems:"flex-start", flexDirection:"column"}}>
              <Typography gutterBottom variant="h4" style={heading}>
              REPORTED CHALLENGES
        </Typography>
        <Box style={boxstyle} sx={{ width:'100%', height:'100%'}}>

        <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell> Milestone </TableCell>
                            <TableCell> Name </TableCell>
                            
                            <TableCell> Date </TableCell>
                            <TableCell> Time </TableCell>
                            <TableCell> Staff </TableCell>
                        
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell> 2 </TableCell>
                            <TableCell> Task </TableCell>
                            <TableCell> DD/MM/YY </TableCell>
                            <TableCell> hr:min </TableCell>
                            <TableCell> Staff </TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
              </Grid>

              
        </Grid>
    </div>
  )
}

export default Challenge