import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { CardContent, Box, Card, Typography } from '@mui/material';
// utils
import mockData from '../../../utils/mock-data';
//
import { varFadeIn, MotionContainer } from '../../../components/animate';

// ----------------------------------------------------------------------


const MOCK_APPS = [...Array(3)].map((_, index) => ({
  id: mockData.id(index),
  image: mockData.image.product(index)
}));

const CarouselImgStyle = styled('img')(({ theme }) => ({
  height: 280,
  width: '100%',
  objectFit: 'contain',
  [theme.breakpoints.up('xl')]: {
    height: 320
  }
}));

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool
};

function CarouselItem({ item, isActive }) {
  const { image, title, description } = item;

  return <Box component="img" alt={title} src={image} sx={{background:"black", width: '100%', height: 480, objectFit: 'contain' }} />;

}

export default function AppFeatured() {
  const theme = useTheme();
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(theme.direction === 'rtl' ? MOCK_APPS.length - 1 : 0);

  const settings = {
    fade:true,
      infinite: true,
      speed: 1000,
      autoplay:true,
      dots: false,
      arrows: false,
   
   
  
  };

  return (
    <Card>
    <Slider ref={carouselRef} {...settings}>
      {MOCK_APPS.map((app, index) => (
        <CarouselItem key={app.id} item={app} isActive={index === currentIndex} />
      ))}
    </Slider>

  </Card>
  );
}
